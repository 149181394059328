module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-136795155-2"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Stella Sweets Desserts","short_name":"Stella Sweets","start_url":"/","background_color":"#a8174a","theme_color":"#a8174a","display":"standalone","icon":"content/assets/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c83df24b8d0fa2e2faccc953248c3e68"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
